<template>
  <div class="right" >
        <div>
          <breadCrumb :siderContent="siderName" />
        </div>
        <div>
          <div v-if="topList.length>0">
                <a-tabs  @change="cutover" :animated="false">
                  <a-tab-pane  :tab="item.storey_name" v-for="(item,index) in topList" :key="index"  >
                    <ul>
                      <li
                        v-for="(item, index) in items.data"
                        :key="index"
                      >
                        <div class="goods">
                          {{ item.table_name }}
                        </div>
                        <div class="h5">
                          <span v-if="judgeNumber(item.table_order_status)" class="activeColor">
                               {{ item.table_order_status }}
                          </span>
                          <span v-if="!judgeNumber(item&&item.table_order_status)">
                               {{ item.table_order_status }}
                          </span> 
                        </div>
                        <div class="xin" @click="clickAdd(item.id)">
                          <div class="myButton"> 新增预定</div>
                        </div>
                      </li>
                    </ul>
                    <div class="page" v-if="totals>0">
                      <a-pagination v-model="currents" :total="totals" @change="onChange" :defaultPageSize="28"/>
                    </div>
                    
                  </a-tab-pane>
                </a-tabs>
                <a-form-model
                  class="myForm"
                  :model="form"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                >
                  <a-form-model-item label="桌台">
                    <a-input
                      v-model="form.name"
                      placeholder="请输入要查询的桌台"
                      @change="onSearchTbale"
                    />
                  </a-form-model-item>
                </a-form-model>
              </div>
        </div>
          <addReserve :isShow="showAddREserve"  @choose="changeList(arguments)" :tablename="curTablename"/>
          
      </div>
 
</template>
<script>
import addReserve from "./addReserve";
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
import {reserveInfo,reserveList,finishReserve} from '@/request/reserve.js';
import { shopStorey } from "@/request/api";
import { compare } from "@/public/common";
import { mapState , mapActions } from "vuex";
const columns = [
  {
    title: "桌台",
    dataIndex: "table_name",
    ellipsis: true,
  },
  {
    title: "姓名",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "手机号",
    dataIndex: "mobile",
    ellipsis: true,
  },
  {
    title: "就餐时间",
    dataIndex: "order_time",
    scopedSlots: { customRender: "way" },
  },
  {
    title: "人数",
    dataIndex: "people",
    ellipsis: true,
  },
  {
    title: "状态",
    dataIndex: "status",
    ellipsis: true,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    dataIndex: "order_clerk",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 4 },
      topList:[],//餐桌区域
      siderName: "预定系统", //sider切换内容
      members: [],
      total:0,//数据条数
      currents:1,//桌台预定每条
      storey_id:'',//区域
      form: {
        name: "",
      },
      items: [],
      totals:0,
      keywords:'',//输入框关键词
      columns,
      current:1,//当前页
      showAddREserve:false,
      curTablename:'',//点击新增当前的桌台名
      showCancel:false,//取消预定modal
         recordEvery:'',//每条列表信息
    };
  },
  components: {
    breadCrumb,addReserve
  },
  computed: {
    ...mapState(["topTabList",'activeName']),
  },
  mounted(){
    this.reserveRequest();
    this.tabList();
    this.keywords="";
    this.keyUp()
  },
  methods:{
    keyUp(){
      const _this=this;
      document.onkeydown = function(e) {
          let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
          if(e1 && e1.keyCode == 120){
              _this.clickAdd();
            }
        }
    },
    ...mapActions(['updateTabListAction','activeNameAction']),
     onChange(current){ //桌台预定分页
      this.currents = current;
      this.reserveRequest()
    },
     judgeNumber(str){ //过滤数字改变字体颜色
      const a = /[0-9]/; 
      return a.test(str)
    },
    clickAdd(table){ //点击新增
      this.showAddREserve=true;
      this.curTablename=table;
    },

    cutover(index){ //点击区域
      this.storey_id=this.topList[index].id ?this.topList[index].id :'';
      this.currents=1;
      this.reserveRequest()
    },
    onSearchTbale(){
      this.currents=1;
      this.reserveRequest()
    },
      reserveRequest(){//列表请求
      const _this=this;
       reserveInfo({
        is_order:1,
        listRows:28,
        page:_this.currents,
        storey_id:_this.storey_id,
        table_name:_this.form.name,
        is_temp:1,
         })
        .then((res) => {
          if(res.data.status===200){
              _this.items=res.data.data.list && res.data.data.list;
              _this.totals=res.data.data.list.total && res.data.data.list.total;
            }               
          })
        .catch(result => {
            return false;
      })
    },

    tabList() {  //餐桌列表
      shopStorey({
        page: 1,
        is_use: 1,
        // shop_id: this.shop_id,
      })
        .then((res) => {
          const topListold = res.data.data.list.data ? res.data.data.list.data : [];
          const topListNew = [];
          topListNew.push({ storey_name: "全部桌台", sort: 0, id: 0 });
          for (let i = 0; i < topListold.length; i++) {
            topListNew.push({
              storey_name: topListold[i].storey_name ? topListold[i].storey_name : "",
              sort: topListold[i].sort ? topListold[i].sort : "",
              id: topListold[i].id ? topListold[i].id : "",
              store_id:topListold[i].store_id ? topListold[i].store_id : "",
            });
          }
          this.topList = topListNew.sort(compare("sort"));
        })
        .catch((error) => {
          return false;
        });
    },
     changeList(number){//新增后更新列表
      this.keyUp()
      if(number[0]){
        this.reserveRequest();
        this.showAddREserve=false;
      }
      if(number[1]){
        this.showAddREserve=false;
      }   
    },
    finsh(id,table_id){  //点击完成预定
      let i = this.topTabList.findIndex(item => item.name == 'Yingye')
      if(i == -1){
        let arr = JSON.parse(JSON.stringify(this.topTabList))
        arr.push({
          title:'营业',
          path:'/yingye',
          name: 'Yingye',
          isDetele:true
        })
        this.updateTabListAction(arr)
      }
      this.activeNameAction('Yingye')
      this.$router.push({ path: '/yingye', query: { reserve_id: id,table_id: table_id} })

    },

  }
};
</script>

<style lang="less" scoped>
.reserve {
  width: 100%;
  display: flex;
  height: 95.4vh;

  .right {
    width: 83vw;
    height: 95.4vh;
    margin: 2vh;
    background: rgba(248, 248, 255, 1);
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    width: 10.8vw;
    height: 16vh;
    margin: 0vh 0.8vw 0.8vw 0;
    padding: 2vh;
    font-size: 1.8vh;
    border-radius: 0.5vh;
    background-color: #edeef2;
    cursor: pointer;
  }
  li:hover {
    background: #ebebeb;
  }
  .goods {
    font-weight: bold;
    text-align: center;
    height: 3.6vh;
  }
  .h5 {
    text-align: center;
    margin: 1vh 0;
    font-size: 1.6vh;
  }
  .xin{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .myButton {
    width: 7vw;
    height: 3.5vh;
    background-color: rgba(255, 103, 0, 1);
    color: white;
    border: none;
    border-radius: 2vh;
    font-size: 1.6vh;
    cursor: pointer;
    text-align: center;
    line-height: 3.5vh;
    padding:0 1vw;
  }
  .myForm {
    position: fixed;
    width: 100%;
    top: 10.8vh;
    left: 64.7vw;
  }
}
.activeColor{
  color:rgba(255, 103, 0, 1);
    margin-left:1.5vw;
}  
   
.page{
  overflow: hidden;
  /deep/.ant-pagination{
    float: right;
    margin-right: 4vw;
} 
} 
/deep/.ant-tabs-bar{
  margin:0 0 10px 0;
}
/deep/.ant-tabs-tab-active{
  font-size: 1.8vh !important;
}
/deep/.ant-input {
  height:3.5vh;
  line-height: 3.5vh;
}
</style>